import React from "react";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import OriginMarkerIcon from "@shared/ui/atoms/icons/OriginMarkerIcon";
import Box from "@mui/material/Box";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import DestinationMarkerIcon from "@shared/ui/atoms/icons/DestinationMarkerIcon";
import { useHistory, useLocation } from "react-router-dom";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import { useSelector, useDispatch } from "react-redux";
import ButtonForm from "@shared/ui/molecules/ButtonForm";
import calculateDistanceKm from "@shared/utils/calculateDistanceKm";
import calculateDuration from "@shared/utils/calculateDuration";
import convertToLowerCase from "@shared/utils/convertToLowerCase";
import { selectCurrentUser } from "@store/authSlice";
import { showLoginDialog } from "@store/dialogSlice";
import { useCreateTransportOrderMutation } from "@api/TranstubeCore/transportOrdersApi";
import routes from "@routes/routes";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel/Panel";
import TransportOrderWizardTemplate from "@shared/ui/templates/TransportOrderWizardTemplate";
import PanelHeader from "@shared/ui/atoms/PanelHeader";
import DateRangeIcon from "@mui/icons-material/DateRange";
import convertDate from "@shared/utils/convertDate";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Trans, useTranslation } from "react-i18next";
import dayjs from "dayjs";
import TransportOrderLineItem from "@shared/ui/molecules/TransportOrderLineItem";
import validationSchema from "./Summary.validationSchema";
import useStyles from "./Summary.styles";
import { IGroupedTransportOrderLineItem } from "../category/category.types";
import detailsValidationSchema from "../details/Details.validationSchema";

const Summary: React.FC<any> = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const routeParams = location.state;
  const history = useHistory();
  const currentUser = useSelector(selectCurrentUser);
  const [createTransportOrder] = useCreateTransportOrderMutation();
  const { i18n } = useTranslation();

  const handleSubmit = async (values: any) => {
    if (!currentUser) {
      dispatch(showLoginDialog());

      return;
    }

    const transportOrderLineItems = values.groupedTransportOrderLineItems
      .map((e: IGroupedTransportOrderLineItem) =>
        e.transportOrderLineItems.map(({ id, ...rest }) => rest)
      )
      .flat()
      .map((transportOrderLineItem: any) => {
        if (["others", "furniture"].includes(transportOrderLineItem.category)) {
          const { dimensionX, dimensionY, dimensionZ, ...rest } =
            transportOrderLineItem;

          return {
            dimension_x: dimensionX,
            dimension_y: dimensionY,
            dimension_z: dimensionZ,
            ...rest
          };
        }

        return transportOrderLineItem;
      });

    const createTransportOrderParams = {
      body: {
        data: {
          attributes: {
            start_on:
              values.isSpecifyShippingDates && values.startOn
                ? dayjs(values.startOn).format("YYYY-MM-DD")
                : null,
            end_on:
              values.isSpecifyShippingDates && values.endOn
                ? dayjs(values.endOn).format("YYYY-MM-DD")
                : null,
            extra_information: values.extraInformation,
            from_location: {
              map_location_id: values.fromLocation.id
            },
            to_location: {
              map_location_id: values.toLocation.id
            },
            auction: {
              currency_iso_code: values.currency,
              offer_preferences: {
                is_user_private_person: values.preferences.privatePerson,
                is_user_company: values.preferences.company,
                is_invoice: values.preferences.invoice
              }
            },
            transport_order_line_items: transportOrderLineItems
          }
        }
      }
    };

    await createTransportOrder(createTransportOrderParams)
      .unwrap()
      .then((payload) => {
        history.replace(
          routes.root.transportOrderCreated(undefined, {
            transport_order_id: payload.data.id
          })
        );
      })
      .catch((ex: any) => {
        if (ex.status === 403) dispatch(showLoginDialog());
      });
  };

  const formik = useFormik({
    initialValues: routeParams,
    validationSchema: validationSchema(i18n),
    onSubmit: handleSubmit
  });

  const comeBack = () => {
    history.replace(routes.root.transportOrders.new.details(), {
      ...routeParams
    });
  };

  const flagFromLocation = () => {
    if (routeParams.fromLocation.countryIsoCode === null) return <></>;

    return (
      <Box pl={1}>
        <sup>
          <img
            loading="lazy"
            src={`https://flagcdn.com/w20/${convertToLowerCase(
              routeParams?.fromLocation?.countryIsoCode
            )}.png`}
            srcSet={`https://flagcdn.com/w40/${convertToLowerCase(
              routeParams?.fromLocation?.countryIsoCode
            )}.png 2x`}
            alt=""
            className={classes.flag}
          />
        </sup>
      </Box>
    );
  };

  const flagToLocation = () => {
    if (routeParams.toLocation.countryIsoCode === null) return <></>;

    return (
      <Box pl={1}>
        <sup>
          <img
            loading="lazy"
            src={`https://flagcdn.com/w20/${convertToLowerCase(
              routeParams?.toLocation?.countryIsoCode
            )}.png`}
            srcSet={`https://flagcdn.com/w40/${convertToLowerCase(
              routeParams?.toLocation?.countryIsoCode
            )}.png 2x`}
            alt=""
            className={classes.flag}
          />
        </sup>
      </Box>
    );
  };

  const empty = () => {
    return (
      <Typography variant="body2" color="text.turquoise.dark">
        <strong>-----</strong>
      </Typography>
    );
  };

  const addressFromLocation = () => {
    if (
      routeParams?.fromLocation &&
      routeParams?.fromLocation?.formattedAddress
    ) {
      return (
        <Typography variant="body2" color="text.grey.main">
          {routeParams.fromLocation &&
            routeParams?.fromLocation?.formattedAddress}
        </Typography>
      );
    }

    return empty();
  };

  const addressToLocation = () => {
    if (routeParams?.toLocation && routeParams?.toLocation?.formattedAddress) {
      return (
        <Typography variant="body2" color="text.grey.main">
          {routeParams.toLocation && routeParams?.toLocation?.formattedAddress}
        </Typography>
      );
    }

    return empty();
  };

  const isCompany = () => {
    if (routeParams.preferences.company === true) {
      return "tak";
    }

    return "nie";
  };

  const isPrivatePerson = () => {
    if (routeParams.preferences.privatePerson === true) {
      return "tak";
    }

    return "nie";
  };

  const displayCategories = () =>
    formik.values.groupedTransportOrderLineItems.map(
      ({ transportOrderLineItems }: IGroupedTransportOrderLineItem) =>
        transportOrderLineItems.map((transportOrderLineItem) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            key={`transportOrderLineItem-${transportOrderLineItem.id}`}
          >
            <Panel>
              <TransportOrderLineItem
                transportOrderLineItem={transportOrderLineItem}
              />
            </Panel>
          </Grid>
        ))
    );

  const dateTimeTransportOrder = () => {
    if (routeParams?.isSpecifyShippingDates === false) {
      return (
        <Typography variant="body2" color="text.grey.main">
          Termin elastyczny
        </Typography>
      );
    }

    return (
      <Stack spacing={1}>
        <Typography variant="body2" color="text.grey.main">
          Od: {convertDate(routeParams?.startOn)}
        </Typography>
        <Typography variant="body2" color="text.grey.main">
          Do: {convertDate(routeParams?.endOn)}
        </Typography>
      </Stack>
    );
  };

  const invoiceVat = () => {
    if (routeParams?.invoice === true) {
      return "tak";
    }

    return "nie";
  };

  if (!detailsValidationSchema(i18n).isValidSync({ ...routeParams })) {
    history.replace(routes.root.transportOrders.new.category());

    return null;
  }

  return (
    <TransportOrderWizardTemplate
      step={3}
      seoProps={{
        robots: "nofollow, noindex, noarchive, nosnippet"
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack direction="column" spacing={2}>
              <Panel>
                <PanelHeader>Trasa i terminy przesyłki</PanelHeader>

                <Stack spacing={2}>
                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1} alignContent="center">
                      <OriginMarkerIcon />

                      <Typography variant="body2" color="text.turquoise.dark">
                        <strong>
                          <Trans i18nKey="labels.from_location" />
                        </strong>
                      </Typography>
                    </Stack>
                    <Box pl={1} display="flex">
                      {addressFromLocation()}
                      {flagFromLocation()}
                    </Box>
                  </Stack>

                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1} alignContent="center">
                      <DestinationMarkerIcon />

                      <Typography variant="body2" color="text.turquoise.dark">
                        <strong>
                          <Trans i18nKey="labels.to_location" />
                        </strong>
                      </Typography>
                    </Stack>
                    <Box pl={1} display="flex">
                      {addressToLocation()}
                      {flagToLocation()}
                    </Box>
                  </Stack>

                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1} alignContent="center">
                      <DateRangeIcon className={classes.iconLabel} />

                      <Typography variant="body2" color="text.turquoise.dark">
                        <strong>Termin realizacji</strong>
                      </Typography>
                    </Stack>
                    <Box pl={1}>{dateTimeTransportOrder()}</Box>
                  </Stack>

                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1} alignContent="center">
                      <AccessTimeFilledIcon className={classes.iconLabel} />
                      <Typography variant="body2" color="text.turquoise.dark">
                        <strong>
                          <Trans i18nKey="labels.duration" />
                        </strong>
                      </Typography>
                    </Stack>
                    <Box pl={1}>
                      <Typography variant="body2" color="text.grey.main">
                        {calculateDuration(routeParams?.duration)}
                      </Typography>
                    </Box>
                  </Stack>

                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1} alignContent="center">
                      <DirectionsCarIcon className={classes.iconLabel} />
                      <Typography variant="body2" color="text.turquoise.dark">
                        <strong>
                          <Trans i18nKey="labels.distance" />
                        </strong>
                      </Typography>
                    </Stack>
                    <Box pl={1}>
                      <Typography variant="body2" color="text.grey.main">
                        {calculateDistanceKm(routeParams?.distance)}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Panel>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Panel>
              <PanelHeader>Dodatkowe informacje</PanelHeader>

              <Stack spacing={2}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1} alignContent="center">
                    <MonetizationOnIcon className={classes.iconLabel} />

                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Waluta</strong>
                    </Typography>
                  </Stack>
                  <Box pl={1}>
                    <Typography variant="body2" color="text.grey.main">
                      {routeParams?.currency}
                    </Typography>
                  </Box>
                </Stack>

                <Stack spacing={1}>
                  <Stack direction="row" spacing={1} alignContent="center">
                    <BusinessIcon className={classes.iconLabel} />

                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Firma</strong>
                    </Typography>
                  </Stack>
                  <Box pl={1}>
                    <Typography variant="body2" color="text.grey.main">
                      {isCompany()}
                    </Typography>
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1} alignContent="center">
                    <ReceiptIcon className={classes.iconLabel} />

                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Faktura VAT</strong>
                    </Typography>
                  </Stack>
                  <Box pl={1}>
                    <Typography variant="body2" color="text.grey.main">
                      {invoiceVat()}
                    </Typography>
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1} alignContent="center">
                    <PersonIcon className={classes.iconLabel} />
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Osoba prywatna</strong>
                    </Typography>
                  </Stack>
                  <Box pl={1}>
                    <Typography variant="body2" color="text.grey.main">
                      {isPrivatePerson()}
                    </Typography>
                  </Box>
                </Stack>

                <Stack spacing={1}>
                  <Stack direction="row" spacing={1} alignContent="center">
                    <FormatAlignCenterIcon className={classes.iconLabel} />
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Informacje dodatkowe</strong>
                    </Typography>
                  </Stack>

                  <Box pl={1}>
                    <Typography
                      variant="body2"
                      color="text.grey.main"
                      sx={{ wordBreak: "break-word" }}
                    >
                      {routeParams?.extraInformation}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Panel>
          </Grid>

          {displayCategories()}
        </Grid>

        <ButtonForm
          comeBack={comeBack}
          buttonName={i18n.t("buttons.create_order")}
          formik={formik}
        />
      </form>
    </TransportOrderWizardTemplate>
  );
};

export default Summary;
