import React from "react";
import PeopleTransportOrderLineItem from "../PeopleTransportOrderLineItem";
import OthersTransportOrderLineItem from "../OthersTransportOrderLineItem";
import AnimalsTransportOrderLineItem from "../AnimalsTransportOrderLineItem";
import FurnitureTransportOrderLineItem from "../FurnitureTransportOrderLineItem";
import VehiclesTransportOrderLineItem from "../VehiclesTransportOrderLineItem";
import FoodsTransportOrderLineItem from "../FoodsTransportOrderLineItem";

const TransportOrderLineItem: React.FC<any> = (props) => {
  const { transportOrderLineItem, displayCategory = true } = props;

  switch (transportOrderLineItem.category) {
    case "people":
      return (
        <PeopleTransportOrderLineItem
          transportOrderLineItem={transportOrderLineItem}
          displayCategory={displayCategory}
        />
      );
    case "others":
      return (
        <OthersTransportOrderLineItem
          transportOrderLineItem={transportOrderLineItem}
          displayCategory={displayCategory}
        />
      );
    case "animals":
      return (
        <AnimalsTransportOrderLineItem
          transportOrderLineItem={transportOrderLineItem}
          displayCategory={displayCategory}
        />
      );
    case "furniture":
      return (
        <FurnitureTransportOrderLineItem
          transportOrderLineItem={transportOrderLineItem}
          displayCategory={displayCategory}
        />
      );
    case "vehicles":
      return (
        <VehiclesTransportOrderLineItem
          transportOrderLineItem={transportOrderLineItem}
          displayCategory={displayCategory}
        />
      );
    case "foods":
      return (
        <FoodsTransportOrderLineItem
          transportOrderLineItem={transportOrderLineItem}
          displayCategory={displayCategory}
        />
      );
    default:
      return null;
  }
};

export default TransportOrderLineItem;
