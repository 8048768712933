import routes from "@routes/routes";
import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { useConfirmEmailMutation } from "@api/TranstubeCore/usersApi";
import BoxInformation from "@shared/ui/molecules/BoxInformation/BoxInformation";
import EmailErrorIcon from "@shared/ui/atoms/icons/EmailError/EmailError";
import useQueryParams from "@shared/hooks/useQueryParams";
import { useTranslation } from "react-i18next";

const ConfirmEmail: React.FC<any> = () => {
  const history = useHistory();
  const [createConfirmEmail] = useConfirmEmailMutation();
  const { i18n } = useTranslation();
  const { token } = useQueryParams<any>();

  const mainPage = () => {
    return routes.root();
  };

  const verifyEmail = async () => {
    const result: any = await createConfirmEmail({
      body: {
        data: {
          attributes: {
            confirmation_token: token
          }
        }
      }
    });

    if (!result.error) {
      history.push(routes.root.emailConfirmed());

      return result;
    }

    return null;
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <BoxInformation
      seoProps={{
        title: "Weryfikacja adresu e-mail",
        robots: "nofollow, noindex, noarchive, nosnippet"
      }}
      actionLeftButton={null}
      actionRightButton={mainPage}
      image={<EmailErrorIcon />}
      title="Niezweryfikowano adresu e-mail"
      description="Upsss..! Coś poszło nie tak. Spróbuj jeszcze raz."
      nameButtonLeft={null}
      nameButtonRight={i18n.t("buttons.home")}
    />
  );
};

export default ConfirmEmail;
