import React from "react";
import { Box } from "@mui/material";
import TravelerIcon from "@shared/ui/atoms/icons/TravelerIcon";
import PawIcon from "@shared/ui/atoms/icons/PawIcon";
import LoveseatIcon from "@shared/ui/atoms/icons/LoveseatIcon";
import BoxTapedIcon from "@shared/ui/atoms/icons/BoxTapedIcon";
import CarBusIcon from "@shared/ui/atoms/icons/CarBusIcon";
import AppleGlassIcon from "@shared/ui/atoms/icons/AppleGlassIcon";
import useStyles from "./TransportOrderLineItemCategory.styles";

const TransportOrderLineItemCategory: React.FC<any> = (props) => {
  const { category } = props;
  const { classes } = useStyles();

  const renderIcon = () => {
    if (category === "people") return <TravelerIcon />;
    if (category === "others") return <BoxTapedIcon />;
    if (category === "animals") return <PawIcon />;
    if (category === "furniture") return <LoveseatIcon />;
    if (category === "vehicles") return <CarBusIcon />;
    if (category === "foods") return <AppleGlassIcon />;

    return null;
  };

  return (
    <Box
      className={classes.box}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {renderIcon()}
    </Box>
  );
};

export default TransportOrderLineItemCategory;
