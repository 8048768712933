import React from "react";
import PawIcon from "@shared/ui/atoms/icons/PawIcon";
import CategoryCheckbox from "../CategoryCheckbox";

const AnimalsCheckbox: React.FC<any> = (props) => {
  return (
    <CategoryCheckbox
      icon={<PawIcon className="icon" height="40px" />}
      label="Zwierzęta"
      {...props}
    />
  );
};

export default AnimalsCheckbox;
