import React from "react";
import AppleGlassIcon from "@shared/ui/atoms/icons/AppleGlassIcon";
import CategoryCheckbox from "../CategoryCheckbox";

const FoodsCheckbox: React.FC<any> = (props) => {
  return (
    <CategoryCheckbox
      icon={<AppleGlassIcon className="icon" height="40px" />}
      label="Jedzenie"
      {...props}
    />
  );
};

export default FoodsCheckbox;
