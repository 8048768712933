import React from "react";
import { Box, Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@shared/ui/atoms/Typography";
import useStyles from "./CategoryCheckbox.styles";

const CategoryCheckbox: React.FC<any> = (props) => {
  const { icon, label, ...rest } = props;
  const { classes } = useStyles();

  return (
    <Checkbox
      sx={{
        "&.MuiCheckbox-root": {
          padding: 0
        }
      }}
      icon={
        <>
          {" "}
          <Box
            className={classes.box}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Stack spacing={1} justifyContent="center" alignItems="center">
              {icon}
              <Typography variant="caption" color="text.grey.main">
                {label}
              </Typography>
            </Stack>
          </Box>
        </>
      }
      checkedIcon={
        <>
          {" "}
          <Box
            className={classes.boxChecked}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Stack spacing={1} justifyContent="center" alignItems="center">
              {icon}
              <Typography variant="caption" color="text.grey.main">
                {label}
              </Typography>
            </Stack>
          </Box>
        </>
      }
      {...rest}
    />
  );
};

export default CategoryCheckbox;
