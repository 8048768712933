import React from "react";
import LoveseatIcon from "@shared/ui/atoms/icons/LoveseatIcon";
import CategoryCheckbox from "../CategoryCheckbox";

const FurnitureCheckbox: React.FC<any> = (props) => {
  return (
    <CategoryCheckbox
      icon={<LoveseatIcon className="icon" height="40px" />}
      label="Meble"
      {...props}
    />
  );
};

export default FurnitureCheckbox;
