import React from "react";
import Box from "@mui/material/Box";
import Typography from "@shared/ui/atoms/Typography";
import { Stack, ButtonGroup, MenuItem, Divider } from "@mui/material";
import CustomAccordion from "@shared/ui/atoms/CustomAccordion/CustomAccordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MultiplyIcon from "@shared/ui/atoms/icons/MultiplyIcon/MultiplyIcon";
import OriginMarkerIcon from "@shared/ui/atoms/icons/OriginMarkerIcon";
import DestinationMarkerIcon from "@shared/ui/atoms/icons/DestinationMarkerIcon/DestinationMarkerIcon";
import Button from "@shared/ui/atoms/Button/Button";
import { useHistory } from "react-router-dom";
import routes from "@routes/routes";
import formattedAddress from "@shared/utils/formattedAddress";
import convertDate from "@shared/utils/convertDate";
import formattedAddressWithComma from "@shared/utils/formattedAddressWithComma";
import TreasureIcon from "@shared/ui/atoms/icons/TreasureIcon";
import { ITransportOrderLineItem } from "@pages/transport-orders/new/category/category.types";
import { Trans } from "react-i18next";
import ButtonMenu from "@shared/ui/molecules/ButtonMenu";
import { useCloseAuctionMutation } from "@api/TranstubeCore/auctionsApi";
import useStyles from "./TransportOrderListItem.styles";
import GroupedTransportOrderLineItem from "../GroupedTransportOrderLineItem";
import TransportOrderLineItemCategory from "../TransportOrderLineItemCategory";

const TransportOrderListItem: React.FC<any> = (props) => {
  const { transportOrder, refetchTransportOrders } = props;
  const history = useHistory();
  const { classes } = useStyles();
  const [closeAuction] = useCloseAuctionMutation();
  const [loading, setLoading] = React.useState(false);

  const handleCloseAuction = async (auctionId: any) => {
    setLoading(true);
    await closeAuction({ id: auctionId });

    await refetchTransportOrders();
    setLoading(false);
  };

  const open = () => {
    history.push(
      routes.root.transportOrders.transportOrder({ id: transportOrder.id })
    );
  };

  const displayData = () => {
    if (transportOrder.startOn === null || transportOrder.endOn === null)
      return (
        <Typography variant="body2" color="text.grey.main">
          Termin elastyczny
        </Typography>
      );

    return (
      <>
        <Typography variant="body2" color="text.grey.main">
          {convertDate(transportOrder.startOn)} -{" "}
          {convertDate(transportOrder.endOn)}
        </Typography>
      </>
    );
  };

  const groupedTransportOrderLineItems =
    transportOrder?.transportOrderLineItems.reduce(
      (
        l: {
          category: string;
          transportOrderLineItems: ITransportOrderLineItem[];
          quantity: number;
        }[],
        p: ITransportOrderLineItem
      ) => {
        let groupedTransportOrderLineItem = l.find(
          (e) => e.category === p.category
        );

        if (!groupedTransportOrderLineItem) {
          groupedTransportOrderLineItem = {
            category: p.category,
            quantity: 0,
            transportOrderLineItems: []
          };

          l.push(groupedTransportOrderLineItem);
        }

        groupedTransportOrderLineItem.transportOrderLineItems.push(p);

        groupedTransportOrderLineItem.quantity += Number(p.quantity || 1);

        return l;
      },
      []
    );

  const displayCategories = () => {
    return groupedTransportOrderLineItems.map(
      (groupedTransportOrderLineItem: any) => (
        <GroupedTransportOrderLineItem
          groupedTransportOrderLineItem={groupedTransportOrderLineItem}
          key={groupedTransportOrderLineItem.category}
        />
      )
    );
  };

  const moreInformation = () => {
    return (
      <>
        <Box sx={{ width: "100%" }}>
          <Stack spacing={2}>
            <Box>
              <Stack spacing={1} textAlign="left">
                <Typography variant="body2" color="text.turquoise.dark">
                  <strong>Numer</strong>
                </Typography>

                <Typography variant="body2" color="text.grey.main">
                  {transportOrder.number}
                </Typography>
              </Stack>
            </Box>
            {transportOrder.extraInformation !== "" ? (
              <Box sx={{ width: { md: "50%" } }}>
                <Stack spacing={1} textAlign="left">
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Informacje dodatkowe</strong>
                  </Typography>

                  <Typography
                    variant="body2"
                    color="text.grey.main"
                    sx={{ wordBreak: "break-word" }}
                  >
                    {transportOrder.extraInformation}
                  </Typography>
                </Stack>
              </Box>
            ) : null}
            <Box>
              <Stack spacing={1} textAlign="left">
                <Typography variant="body2" color="text.turquoise.dark">
                  <strong>Termin realizacji</strong>
                </Typography>

                {displayData()}
              </Stack>
            </Box>
            <Box>
              {" "}
              <Stack spacing={1} textAlign="left">
                <Typography variant="body2" color="text.turquoise.dark">
                  <strong>Przesyłki</strong>
                </Typography>

                <Stack spacing={2} divider={<Divider light />}>
                  {displayCategories()}
                </Stack>
              </Stack>
            </Box>
            <Box
              sx={{ display: { sm: "block", md: "none" } }}
              textAlign="center"
            >
              {transportOrder.type === "transportOrder" ? (
                <ButtonGroup>
                  <Button size="small" variant="outlined" onClick={open}>
                    Zobacz
                  </Button>

                  <ButtonMenu size="small" variant="outlined">
                    <MenuItem
                      onClick={(event: any) => {
                        event.stopPropagation();
                        handleCloseAuction(transportOrder.auction.id);
                      }}
                      disabled={
                        loading || transportOrder.auction.status === "closed"
                      }
                    >
                      <Typography variant="body2" color="text.turquoise.dark">
                        Wycofaj zlecenie
                      </Typography>
                    </MenuItem>
                  </ButtonMenu>
                </ButtonGroup>
              ) : (
                <Button size="small" variant="outlined" onClick={open}>
                  Zobacz
                </Button>
              )}
            </Box>
          </Stack>
        </Box>
      </>
    );
  };

  const categories = () => {
    if (groupedTransportOrderLineItems.length > 1) {
      return (
        <Box
          className={classes.box}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <TreasureIcon />
        </Box>
      );
    }

    return (
      <Stack spacing={1} direction="row">
        <TransportOrderLineItemCategory
          category={groupedTransportOrderLineItems[0].category}
        />

        <Box alignSelf="center" display="flex">
          <MultiplyIcon />
        </Box>

        <Box alignSelf="center" display="flex">
          <Typography variant="body2" color="text.grey.main">
            {groupedTransportOrderLineItems[0].quantity}
          </Typography>
        </Box>
      </Stack>
    );
  };

  return (
    <CustomAccordion key={transportOrder.id}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div style={{ width: "100%" }}>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              alignItems: { md: "center" }
            }}
          >
            <Box sx={{ width: { md: "15%" } }}>{categories()}</Box>

            <Box sx={{ width: { md: "25%" } }}>
              <Stack spacing={1} direction="row">
                <Box alignSelf="center">
                  <OriginMarkerIcon />
                </Box>
                <Typography variant="body2" color="text.grey.main">
                  {formattedAddress(transportOrder.fromLocation)}
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ width: { md: "25%" } }}>
              <Stack spacing={1} direction="row">
                <Box alignSelf="center">
                  <DestinationMarkerIcon />
                </Box>
                <Typography variant="body2" color="text.grey.main">
                  {formattedAddress(transportOrder.toLocation)}
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ width: { md: "20%" } }}>
              <Typography variant="body2" color="text.grey.main">
                {convertDate(transportOrder.createdAt)}
              </Typography>
            </Box>
            <Box sx={{ width: { md: "15%" } }}>
              {transportOrder.type === "transportOrder" ? (
                <ButtonGroup>
                  <Button size="small" variant="outlined" onClick={open}>
                    Zobacz
                  </Button>

                  <ButtonMenu size="small" variant="outlined">
                    <MenuItem
                      onClick={(event: any) => {
                        event.stopPropagation();
                        handleCloseAuction(transportOrder.auction.id);
                      }}
                      disabled={
                        loading || transportOrder.auction.status === "closed"
                      }
                    >
                      <Typography variant="body2" color="text.turquoise.dark">
                        Wycofaj zlecenie
                      </Typography>
                    </MenuItem>
                  </ButtonMenu>
                </ButtonGroup>
              ) : (
                <Button size="small" variant="outlined" onClick={open}>
                  Zobacz
                </Button>
              )}
            </Box>
          </Box>
          <Box sx={{ display: { md: "none" } }}>
            <Stack spacing={2}>
              <Box>{categories()}</Box>
              <Box>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <OriginMarkerIcon />

                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>
                        <Trans i18nKey="labels.from_location" />
                      </strong>
                    </Typography>
                  </Stack>
                  <Typography variant="body2" color="text.grey.main">
                    {formattedAddressWithComma(transportOrder.fromLocation)}
                  </Typography>
                </Stack>
              </Box>
              <Box>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <DestinationMarkerIcon />

                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>
                        <Trans i18nKey="labels.to_location" />
                      </strong>
                    </Typography>
                  </Stack>
                  <Typography variant="body2" color="text.grey.main">
                    {formattedAddressWithComma(transportOrder.toLocation)}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row" spacing={1} pt={2}>
          {moreInformation()}
        </Stack>
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default TransportOrderListItem;
