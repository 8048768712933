import React from "react";
import CarBusIcon from "@shared/ui/atoms/icons/CarBusIcon";
import CategoryCheckbox from "../CategoryCheckbox";

const OthersCheckbox: React.FC<any> = (props) => {
  return (
    <CategoryCheckbox
      icon={<CarBusIcon className="icon" height="40px" />}
      label="Pojazdy"
      {...props}
    />
  );
};

export default OthersCheckbox;
