import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const formPanel = (theme: Theme) => {
  return {
    display: "grid",
    gridGap: "8px 16px",
    [theme.breakpoints.up("md")]: {
      width: "638px"
    },
    "> p": {
      marginTop: "8px"
    }
  };
};

const useSectionStyles = makeStyles()((theme: Theme) => ({
  peoplePanel: {
    gridTemplateAreas: `
      "c"
      "ql"
      "q"
    `,
    gridTemplateColumns: "1fr 1fr",
    [theme.breakpoints.up("md")]: {
      width: "638px"
    },
    ...formPanel(theme)
  },
  foodsPanel: {
    gridTemplateAreas: `
      "c c l l"
      "nl nl wl wl"
      "n n w w"
      "f f f f"
    `,
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    ...formPanel(theme)
  },
  othersPanel: {
    gridTemplateAreas: `
      "c l"
      "nl nl"
      "n n"
      "dl dl"
      "d d"
      "wl ql"
      "w q"
      "f f"
    `,
    gridTemplateColumns: "1fr 1fr",
    ...formPanel(theme)
  },
  animalsPanel: {
    gridTemplateAreas: `
      "c l"
      "nl nl"
      "n n"
      "wl ql"
      "w q"
      "f f"
    `,
    gridTemplateColumns: "1fr 1fr",
    ...formPanel(theme)
  },
  dimensionsLabel: {
    gridArea: "dl"
  },
  weightLabel: {
    gridArea: "wl"
  },
  nameLabel: {
    gridArea: "nl"
  },
  quantityLabel: {
    gridArea: "ql"
  },
  category: {
    gridArea: "c",
    [theme.breakpoints.up("md")]: {
      paddingTop: "3px"
    },
    svg: {
      display: "block"
    }
  },
  dimensions: {
    gridArea: "d",
    div: {
      lineHeight: "37.13px"
    }
  },
  weight: {
    gridArea: "w"
  },
  name: {
    gridArea: "n"
  },
  quantity: {
    gridArea: "q"
  },
  last: {
    gridArea: "l",
    justifySelf: "right",
    alignContent: "center",
    [theme.breakpoints.up("md")]: {
      paddingTop: "7px",
      alignContent: "normal"
    },
    svg: {
      display: "block"
    }
  },
  footer: {
    gridArea: "f",
    marginTop: theme.spacing(1)
  }
}));

export default useSectionStyles;
