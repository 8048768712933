import React from "react";
import { useParams } from "react-router-dom";
import TextField from "@shared/ui/atoms/TextField";
import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";
import { useGetPublicTransportOrderQuery } from "@api/TranstubeCore/transportOrdersApi";
import {
  useCreateDiscussionMutation,
  useGetPublicDiscussionsQuery
} from "@api/TranstubeCore/discussionsApi";
import { useFormik } from "formik";
import { selectCurrentUser } from "@store/authSlice";
import { useSelector } from "react-redux";
import Discussion from "@shared/ui/organisms/Discussion";
import OrderTemplate from "@shared/ui/templates/OrderTemplate";
import LinearDeterminate from "@shared/ui/molecules/LinearDeterminate/LinearDeterminate";
import NoMatch from "@pages/noMatch/NoMatch";
import handleFormError from "@shared/utils/handleFormError";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import validationSchema from "./Messages.validationSchema";
import useStyles from "./Messages.styles";
import { TTransportOrderMessages } from "./Messages.types";

const TransportOrderMessages: React.FC<TTransportOrderMessages> = (props) => {
  const { publicTransportOrder, publicDiscussions, refetchDiscussions } = props;
  const { classes } = useStyles();
  const { i18n } = useTranslation();
  const [createDiscussion] = useCreateDiscussionMutation();
  const currentUser = useSelector(selectCurrentUser);

  const handleSubmit = async (values: any, actions: any) => {
    const createDiscussionParams = {
      body: {
        data: {
          attributes: {
            auction_id: publicTransportOrder.auction.id,
            user_id: currentUser?.id,
            message: { content: values.messageContent }
          }
        }
      }
    };

    await createDiscussion(createDiscussionParams)
      .unwrap()
      .then(async () => {
        actions.resetForm();
        await refetchDiscussions();
      })
      .catch((ex: any) => {
        const pointers = {
          "/data/attributes/message/content": {
            field: "messageContent",
            i18n: "message"
          }
        };

        ex.data?.errors?.forEach((e: any) => {
          handleFormError(pointers, e, i18n, actions);
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      messageContent: ""
    },
    validationSchema: validationSchema(),
    onSubmit: handleSubmit
  });

  const onKeyDown = (e: any) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  const displayTextField = () => {
    const disccusionUser = publicDiscussions?.find(
      (discussion) => discussion?.user?.id === currentUser?.id
    )?.user?.id;

    if (
      disccusionUser === undefined &&
      !disccusionUser &&
      currentUser !== null &&
      publicTransportOrder?.auction?.user?.id !== currentUser?.id &&
      publicTransportOrder?.auction?.status === "opened"
    ) {
      return (
        <form
          onSubmit={formik.handleSubmit}
          onKeyDown={onKeyDown}
          role="presentation"
        >
          <TextField
            className={classes.input}
            InputProps={{
              inputProps: {
                spellCheck: "true",
                autoCorrect: "on"
              },
              disableUnderline: true,
              className: classes.textFieldInput,
              sx: { fontSize: "0.875rem", marginTop: "-15px" },
              endAdornment: (
                <Fab
                  color="secondary"
                  aria-label="add"
                  className={classes.fabButton}
                  type="submit"
                >
                  <NavigationIcon className={classes.svgNavigation} />
                </Fab>
              )
            }}
            value={formik.values.messageContent}
            onChange={formik.handleChange}
            error={
              formik.touched.messageContent &&
              Boolean(formik.errors.messageContent)
            }
            helperText={
              formik.touched.messageContent && formik.errors.messageContent
            }
            name="messageContent"
            placeholder="Zadaj pytanie..."
            fullWidth
            margin="normal"
            variant="standard"
          />
        </form>
      );
    }

    return <></>;
  };

  return (
    <OrderTemplate
      seoProps={{
        title: "Wiadomości zlecenia przewozu",
        robots: "nofollow, noindex, noarchive, nosnippet"
      }}
    >
      {displayTextField()}

      <Stack spacing={1}>
        {publicDiscussions.map((element) => (
          <div key={element.id}>
            <Discussion
              transportOrder={publicTransportOrder}
              discussion={element}
              refetchDiscussions={refetchDiscussions}
            />
          </div>
        ))}
      </Stack>
    </OrderTemplate>
  );
};

const Messages: React.FC = () => {
  const { id: transportOrderId } = useParams<any>();

  const { data: publicTransportOrderResult = { data: undefined }, error = {} } =
    useGetPublicTransportOrderQuery({ id: transportOrderId });

  const { data: publicTransportOrder } = publicTransportOrderResult;

  const {
    data: discussionsResult = { data: undefined },
    refetch: refetchDiscussions
  } = useGetPublicDiscussionsQuery(
    {
      params: {
        filter: {
          auction_id_eq: publicTransportOrder?.auction?.id
        }
      }
    },
    { skip: !publicTransportOrder }
  );

  const { data: publicDiscussions } = discussionsResult;

  if ("status" in error && error.status === 404) {
    return <NoMatch />;
  }

  if (!publicTransportOrder || !publicDiscussions) return <LinearDeterminate />;

  return (
    <TransportOrderMessages
      publicTransportOrder={publicTransportOrder}
      publicDiscussions={publicDiscussions}
      refetchDiscussions={refetchDiscussions}
    />
  );
};

export default Messages;
