import React from "react";
import AppleGlass from "@assets/images/category_icons/apple_glass.svg";
import SVG from "react-inlinesvg";
import useColorFromPalette from "@shared/hooks/useColorFromPalette";

const AppleGlassIcon: React.FC<any> = (props) => {
  const { color = "turquoise.main", ...iconProps } = props;
  const colorValue = useColorFromPalette(color);

  return (
    <SVG
      src={AppleGlass}
      width={null}
      height={32}
      color={colorValue}
      {...iconProps}
    />
  );
};

export default AppleGlassIcon;
