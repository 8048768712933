import React from "react";
import CarBus from "@assets/images/category_icons/car_bus.svg";
import SVG from "react-inlinesvg";
import useColorFromPalette from "@shared/hooks/useColorFromPalette";

const CarBusIcon: React.FC<any> = (props) => {
  const { color = "turquoise.main", ...iconProps } = props;
  const colorValue = useColorFromPalette(color);

  return (
    <SVG
      src={CarBus}
      width={null}
      height={32}
      color={colorValue}
      {...iconProps}
    />
  );
};

export default CarBusIcon;
