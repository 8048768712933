import React from "react";
import Paw from "@assets/images/category_icons/paw.svg";
import SVG from "react-inlinesvg";
import useColorFromPalette from "@shared/hooks/useColorFromPalette";

const PawIcon: React.FC<any> = (props) => {
  const { color = "turquoise.main", ...iconProps } = props;
  const colorValue = useColorFromPalette(color);

  return (
    <SVG src={Paw} width={null} height={32} color={colorValue} {...iconProps} />
  );
};

export default PawIcon;
