import React from "react";
import TransportOrderWizardTemplate from "@shared/ui/templates/TransportOrderWizardTemplate";
import { useHistory, useLocation } from "react-router-dom";
import routes from "@routes/routes";
import { Stack } from "@mui/material";
import ButtonForm from "@shared/ui/molecules/ButtonForm";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import TransportOrderLineItemFormPanel from "@shared/ui/organisms/TransportOrderLineItemFormPanel";
import CategoriesFormPanel from "@shared/ui/organisms/CategoriesFormPanel";
import { IFormik, ITransportOrderLineItem } from "./category.types";
import validationSchema from "./Category.validationSchema";

const Category: React.FC<any> = () => {
  const history = useHistory();
  const location = useLocation();
  const { i18n } = useTranslation();
  const routeParams = { ...location.state };

  const handleSubmit = (values: IFormik) => {
    history.replace(routes.root.transportOrders.new.schedule(), values);
  };

  const formik = useFormik<IFormik>({
    initialValues: {
      categories: {
        others: false,
        people: false,
        animals: false,
        furniture: false,
        vehicles: false,
        foods: false
      },
      groupedTransportOrderLineItems: [],
      ...routeParams
    },
    validationSchema: validationSchema(i18n),
    onSubmit: handleSubmit
  });

  const totalTransportOrderLineItems = () =>
    formik.values.groupedTransportOrderLineItems.reduce(
      (sum, current) => sum + current.transportOrderLineItems.length,
      0
    );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const transportOrderLineItemsLength = totalTransportOrderLineItems();

    if (event.target.checked && transportOrderLineItemsLength >= 10) return;

    formik.handleChange(event);
    formik.setTouched({}, false);

    const groupedTransportOrderLineItems = [
      ...formik.values.groupedTransportOrderLineItems
    ];

    const categories: { [key: string]: string } = {
      "categories.people": "people",
      "categories.animals": "animals",
      "categories.foods": "foods",
      "categories.furniture": "furniture",
      "categories.vehicles": "vehicles",
      "categories.others": "others"
    };

    const category = categories[event.target.name];

    if (!event.target.checked) {
      formik.setFieldValue(
        "groupedTransportOrderLineItems",
        groupedTransportOrderLineItems.filter((e) => e.category !== category)
      );

      return;
    }

    let groupedTransportOrderLineItem = groupedTransportOrderLineItems.find(
      (e) => e.category === category
    );

    if (groupedTransportOrderLineItem !== undefined) return;

    let transportOrderLineItem = null;

    if (category === "people") {
      transportOrderLineItem = {
        id: new Date().getTime(),
        quantity: "",
        category
      };
    } else if (category === "animals") {
      transportOrderLineItem = {
        id: new Date().getTime(),
        quantity: "",
        name: "",
        weight: "",
        category
      };
    } else if (category === "foods") {
      transportOrderLineItem = {
        id: new Date().getTime(),
        name: "",
        weight: "",
        category
      };
    } else {
      transportOrderLineItem = {
        id: new Date().getTime(),
        quantity: "",
        name: "",
        dimensionX: "",
        dimensionY: "",
        dimensionZ: "",
        weight: "",
        category
      };
    }

    groupedTransportOrderLineItem = {
      category,
      transportOrderLineItems: [transportOrderLineItem]
    };

    groupedTransportOrderLineItems.unshift(groupedTransportOrderLineItem);

    formik.setFieldValue(
      "groupedTransportOrderLineItems",
      groupedTransportOrderLineItems
    );
  };

  const deletePackage = (item: ITransportOrderLineItem) => {
    const groupedTransportOrderLineItems = [
      ...formik.values.groupedTransportOrderLineItems
    ];

    const groupedTransportOrderLineItem = groupedTransportOrderLineItems.find(
      (e) => e.category === item.category
    );

    if (groupedTransportOrderLineItem === undefined) return;

    groupedTransportOrderLineItem.transportOrderLineItems =
      groupedTransportOrderLineItem.transportOrderLineItems.filter(
        (e) => e !== item
      );

    formik.setTouched({}, false);

    formik.setFieldValue(
      "transportOrderLineItems",
      groupedTransportOrderLineItems
    );
  };

  const mainPage = () => {
    history.replace(routes.root());
  };

  const addTransportOrderLineItem = (category: string) => {
    const groupedTransportOrderLineItems = [
      ...formik.values.groupedTransportOrderLineItems
    ];

    const groupedTransportOrderLineItem = groupedTransportOrderLineItems.find(
      (e) => e.category === category
    );

    if (groupedTransportOrderLineItem === undefined) return;

    let transportOrderLineItem = null;

    if (category === "people") {
      transportOrderLineItem = {
        id: new Date().getTime(),
        quantity: "",
        category
      };
    } else if (category === "animals") {
      transportOrderLineItem = {
        id: new Date().getTime(),
        quantity: "",
        name: "",
        weight: "",
        category
      };
    } else if (category === "foods") {
      transportOrderLineItem = {
        id: new Date().getTime(),
        name: "",
        weight: "",
        category
      };
    } else {
      transportOrderLineItem = {
        id: new Date().getTime(),
        name: "",
        quantity: "",
        dimensionX: "",
        dimensionY: "",
        dimensionZ: "",
        weight: "",
        category
      };
    }

    groupedTransportOrderLineItem.transportOrderLineItems?.push(
      transportOrderLineItem
    );

    formik.setTouched({}, false);

    formik.setFieldValue(
      "groupedTransportOrderLineItems",
      groupedTransportOrderLineItems
    );
  };

  return (
    <TransportOrderWizardTemplate step={0}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={1}>
          <CategoriesFormPanel formik={formik} handleChange={handleChange} />

          {formik.values.groupedTransportOrderLineItems.map(
            ({ transportOrderLineItems }, gtoliIndex) =>
              transportOrderLineItems.map(
                (transportOrderLineItem, toliIndex) => (
                  <TransportOrderLineItemFormPanel
                    transportOrderLineItem={transportOrderLineItem}
                    gtoliIndex={gtoliIndex}
                    toliIndex={toliIndex}
                    formik={formik}
                    addTransportOrderLineItem={
                      transportOrderLineItems.length - 1 === toliIndex &&
                      totalTransportOrderLineItems() < 10
                        ? addTransportOrderLineItem
                        : undefined
                    }
                    deletePackage={
                      transportOrderLineItems.length > 1
                        ? deletePackage
                        : undefined
                    }
                    key={`transportOrderLineItem-${transportOrderLineItem.id}`}
                  />
                )
              )
          )}
        </Stack>

        <ButtonForm
          comeBack={mainPage}
          buttonName={i18n.t("buttons.next")}
          formik={formik}
        />
      </form>
    </TransportOrderWizardTemplate>
  );
};

export default Category;
