import React from "react";
import { Box, FormHelperText, Stack } from "@mui/material";
import PanelHeader from "@shared/ui/atoms/PanelHeader";
import AnimalsCheckbox from "@shared/ui/molecules/checkboxes/AnimalsCheckbox";
import FurnitureCheckbox from "@shared/ui/molecules/checkboxes/FurnitureCheckbox";
import OthersCheckbox from "@shared/ui/molecules/checkboxes/OthersCheckbox";
import PeopleCheckbox from "@shared/ui/molecules/checkboxes/PeopleCheckbox";
import VehiclesCheckbox from "@shared/ui/molecules/checkboxes/VehiclesCheckbox";
import Panel from "@shared/ui/molecules/Panel";
import FoodsCheckbox from "@shared/ui/molecules/checkboxes/FoodsCheckbox";

const CategoriesFormPanel: React.FC<any> = (props) => {
  const { formik, handleChange } = props;

  return (
    <Panel>
      <PanelHeader>Kategorie</PanelHeader>

      <Stack spacing={2}>
        <Box>
          <FormHelperText
            error={
              formik.touched.categories && Boolean(formik.errors.categories)
            }
          >
            {formik.touched.categories && formik.errors.categories}
          </FormHelperText>
        </Box>

        <Stack flexWrap="wrap" direction="row" gap={2}>
          <PeopleCheckbox
            checked={formik.values.categories.people}
            onChange={handleChange}
            name="categories.people"
          />

          <AnimalsCheckbox
            checked={formik.values.categories.animals}
            onChange={handleChange}
            name="categories.animals"
          />

          <FoodsCheckbox
            checked={formik.values.categories.foods}
            onChange={handleChange}
            name="categories.foods"
          />

          <FurnitureCheckbox
            checked={formik.values.categories.furniture}
            onChange={handleChange}
            name="categories.furniture"
          />

          <VehiclesCheckbox
            checked={formik.values.categories.vehicles}
            onChange={handleChange}
            name="categories.vehicles"
          />

          <OthersCheckbox
            checked={formik.values.categories.others}
            onChange={handleChange}
            name="categories.others"
          />
        </Stack>
      </Stack>
    </Panel>
  );
};

export default CategoriesFormPanel;
