import React from "react";
import OthersTransportOrderLineItem from "../OthersTransportOrderLineItem";

const FurnitureTransportOrderLineItem: React.FC<any> = (props) => {
  const { transportOrderLineItem, displayCategory = true } = props;

  return (
    <OthersTransportOrderLineItem
      transportOrderLineItem={transportOrderLineItem}
      displayCategory={displayCategory}
    />
  );
};

export default FurnitureTransportOrderLineItem;
