import React from "react";
import Orders from "@assets/images/category_icons/person_walking.svg";
import SVG from "react-inlinesvg";
import useColorFromPalette from "@shared/hooks/useColorFromPalette";

const TravelerIcon: React.FC<any> = (props) => {
  const { color = "turquoise.main", ...iconProps } = props;
  const colorValue = useColorFromPalette(color);

  return <SVG src={Orders} height={32} color={colorValue} {...iconProps} />;
};

export default TravelerIcon;
