import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  accordion: {
    "& .MuiButtonBase-root": {
      padding: 0
    },
    "& .MuiAccordionSummary-content": {
      margin: 0
    },
    "& .MuiAccordionDetails-root": {
      padding: 0
    }
  }
}));

export default useStyles;
