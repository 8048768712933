import React from "react";
import Loveseat from "@assets/images/category_icons/loveseat.svg";
import SVG from "react-inlinesvg";
import useColorFromPalette from "@shared/hooks/useColorFromPalette";

const LoveseatIcon: React.FC<any> = (props) => {
  const { color = "turquoise.main", ...iconProps } = props;
  const colorValue = useColorFromPalette(color);

  return <SVG src={Loveseat} height={32} color={colorValue} {...iconProps} />;
};

export default LoveseatIcon;
