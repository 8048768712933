import * as React from "react";
import MuiAccordion from "@mui/material/Accordion";

const CustomAccordion: React.FC<any> = (props) => {
  const { children, ...rest } = props;

  return (
    <div>
      <MuiAccordion square disableGutters {...rest}>
        {children}
      </MuiAccordion>
    </div>
  );
};

export default CustomAccordion;
