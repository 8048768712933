import React from "react";
import Typography from "@shared/ui/atoms/Typography";
import { Stack } from "@mui/material";
import TransportOrderLineItemCategory from "../TransportOrderLineItemCategory";

const AnimalsTransportOrderLineItem: React.FC<any> = (props) => {
  const { transportOrderLineItem, displayCategory = true } = props;

  return (
    <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
      {displayCategory ? (
        <TransportOrderLineItemCategory
          category={transportOrderLineItem.category}
        />
      ) : null}

      <Stack
        spacing={{ xs: 2, md: 4 }}
        direction={{ xs: "column", md: "row" }}
        alignItems={{ md: "center" }}
      >
        <Stack spacing={1} direction="column">
          <Typography variant="body2" color="text.turquoise.dark">
            <strong>Nazwa</strong>
          </Typography>

          <Typography variant="body2" color="text.grey.main">
            {transportOrderLineItem?.name}
          </Typography>
        </Stack>

        <Stack spacing={1} direction="column">
          <Typography variant="body2" color="text.turquoise.dark">
            <strong>Waga (1 szt.)</strong>
          </Typography>

          <Stack spacing={0.5} direction="row">
            <Typography variant="body2" color="text.grey.main">
              {transportOrderLineItem?.weight}
            </Typography>

            <Typography variant="body2" color="text.grey.main">
              kg
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing={1} direction="column">
          <Typography variant="body2" color="text.turquoise.dark">
            <strong>Ilość</strong>
          </Typography>

          <Stack spacing={0.5} direction="row">
            <Typography variant="body2" color="text.grey.main">
              {transportOrderLineItem?.quantity}
            </Typography>

            <Typography variant="body2" color="text.grey.main">
              szt.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AnimalsTransportOrderLineItem;
