import * as yup from "yup";

const validationSchema = (i18n: any) => {
  return yup.object({
    categories: yup
      .object()
      .shape({
        others: yup.boolean().required(),
        people: yup.boolean().required(),
        animals: yup.boolean().required(),
        furniture: yup.boolean().required(),
        foods: yup.boolean().required(),
        vehicles: yup.boolean().required()
      })
      .test(
        "include-true",
        i18n.t("yup.validations.base.no_category_selected"),
        (values: any) => Object.values(values).includes(true)
      ),
    groupedTransportOrderLineItems: yup
      .array()
      .min(1)
      .of(
        yup
          .object()
          .shape({
            transportOrderLineItems: yup
              .array()
              .min(1)
              .of(
                yup.object().shape({
                  quantity: yup.number().when("category", {
                    is: (value: string) =>
                      [
                        "others",
                        "furniture",
                        "people",
                        "vehicles",
                        "animals"
                      ].includes(value),
                    then: (schema) =>
                      schema
                        .required()
                        .positive()
                        .integer()
                        .moreThan(0)
                        .lessThan(1000)
                  }),
                  dimensionX: yup.number().when("category", {
                    is: (value: string) =>
                      ["others", "furniture", "vehicles"].includes(value),
                    then: (schema) =>
                      schema
                        .required()
                        .positive()
                        .integer()
                        .moreThan(0)
                        .lessThan(1000)
                  }),
                  dimensionY: yup.number().when("category", {
                    is: (value: string) =>
                      ["others", "furniture", "vehicles"].includes(value),
                    then: (schema) =>
                      schema
                        .required()
                        .positive()
                        .integer()
                        .moreThan(0)
                        .lessThan(1000)
                  }),
                  dimensionZ: yup.number().when("category", {
                    is: (value: string) =>
                      ["others", "furniture", "vehicles"].includes(value),
                    then: (schema) =>
                      schema
                        .required()
                        .positive()
                        .integer()
                        .moreThan(0)
                        .lessThan(1000)
                  }),
                  weight: yup.number().when("category", {
                    is: (value: string) =>
                      [
                        "others",
                        "furniture",
                        "animals",
                        "foods",
                        "vehicles"
                      ].includes(value),
                    then: (schema) =>
                      schema
                        .required()
                        .positive()
                        .integer()
                        .moreThan(0)
                        .lessThan(100000)
                  }),
                  name: yup.string().when("category", {
                    is: (value: string) =>
                      [
                        "others",
                        "furniture",
                        "animals",
                        "foods",
                        "vehicles"
                      ].includes(value),
                    then: (schema) => schema.required().min(1).max(50)
                  })
                })
              )
              .required()
          })
          .required()
      )
      .required()
  });
};

export default validationSchema;
