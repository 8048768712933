import React from "react";
import Panel from "@shared/ui/molecules/Panel";
import { getIn } from "formik";
import Typography from "@shared/ui/atoms/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import TravelerIcon from "@shared/ui/atoms/icons/TravelerIcon";
import TextField from "@shared/ui/atoms/TextField";
import MultiplyIcon from "@shared/ui/atoms/icons/MultiplyIcon";
import PawIcon from "@shared/ui/atoms/icons/PawIcon";
import LoveseatIcon from "@shared/ui/atoms/icons/LoveseatIcon";
import BoxTapedIcon from "@shared/ui/atoms/icons/BoxTapedIcon";
import { Box, Button, Stack } from "@mui/material";
import AppleGlassIcon from "@shared/ui/atoms/icons/AppleGlassIcon";
import CarBusIcon from "@shared/ui/atoms/icons/CarBusIcon";
import useStyles from "./TransportOrderLineItemFormPanel.styles";
import {
  TFoodsFormPanel,
  TFurnitureFormPanel,
  TAnimalsFormPanel,
  TOthersFormPanel,
  TPeopleFormPanel,
  TTransportOrderLineItemFormPanel,
  TVehiclesFormPanel
} from "./TransportOrderLineItemFormPanel.types";

const PeopleFormPanel: React.FC<TPeopleFormPanel> = (props) => {
  const { transportOrderLineItem, gtoliIndex, toliIndex, formik } = props;

  const { classes } = useStyles();

  return (
    <Panel>
      <Box className={classes.peoplePanel}>
        <Box className={classes.category}>
          <TravelerIcon />
        </Box>

        <Typography
          variant="body2"
          color="text.turquoise.dark"
          className={classes.quantityLabel}
        >
          <strong>Liczba</strong>
        </Typography>

        <TextField
          name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.quantity`}
          value={transportOrderLineItem.quantity}
          onChange={formik.handleChange}
          error={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.quantity &&
            Boolean(
              getIn(
                getIn(
                  formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                  "transportOrderLineItems"
                )?.[toliIndex],
                "quantity"
              )
            )
          }
          helperText={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.quantity &&
            getIn(
              getIn(
                formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                "transportOrderLineItems"
              )?.[toliIndex],
              "quantity"
            )
          }
          InputProps={{
            endAdornment: (
              <Typography variant="body2" color="text.turquoise.grey">
                os.
              </Typography>
            )
          }}
          className={classes.quantity}
        />
      </Box>
    </Panel>
  );
};

const AnimalsFormPanel: React.FC<TAnimalsFormPanel> = (props) => {
  const {
    transportOrderLineItem,
    gtoliIndex,
    toliIndex,
    formik,
    addTransportOrderLineItem,
    deletePackage
  } = props;

  const { classes } = useStyles();

  return (
    <Panel>
      <Box className={classes.animalsPanel}>
        <Typography
          variant="body2"
          color="text.turquoise.dark"
          className={classes.nameLabel}
        >
          <strong>Nazwa</strong>
        </Typography>

        <Typography
          variant="body2"
          color="text.turquoise.dark"
          className={classes.weightLabel}
        >
          <strong>Waga (1 szt.)</strong>
        </Typography>

        <Typography
          variant="body2"
          color="text.turquoise.dark"
          className={classes.quantityLabel}
        >
          <strong>Liczba</strong>
        </Typography>

        <div className={classes.category}>
          <PawIcon />
        </div>

        <TextField
          name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.name`}
          value={transportOrderLineItem.name}
          onChange={formik.handleChange}
          error={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.name &&
            Boolean(
              getIn(
                getIn(
                  formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                  "transportOrderLineItems"
                )?.[toliIndex],
                "name"
              )
            )
          }
          helperText={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.name &&
            getIn(
              getIn(
                formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                "transportOrderLineItems"
              )?.[toliIndex],
              "name"
            )
          }
          className={classes.name}
        />

        <TextField
          name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.weight`}
          value={transportOrderLineItem.weight}
          onChange={formik.handleChange}
          error={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.weight &&
            Boolean(
              getIn(
                getIn(
                  formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                  "transportOrderLineItems"
                )?.[toliIndex],
                "weight"
              )
            )
          }
          helperText={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.weight &&
            getIn(
              getIn(
                formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                "transportOrderLineItems"
              )?.[toliIndex],
              "weight"
            )
          }
          InputProps={{
            endAdornment: (
              <Typography variant="body2" color="text.turquoise.grey">
                kg
              </Typography>
            )
          }}
          className={classes.weight}
        />

        <TextField
          name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.quantity`}
          value={transportOrderLineItem.quantity}
          onChange={formik.handleChange}
          error={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.quantity &&
            Boolean(
              getIn(
                getIn(
                  formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                  "transportOrderLineItems"
                )?.[toliIndex],
                "quantity"
              )
            )
          }
          helperText={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.quantity &&
            getIn(
              getIn(
                formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                "transportOrderLineItems"
              )?.[toliIndex],
              "quantity"
            )
          }
          InputProps={{
            endAdornment: (
              <Typography variant="body2" color="text.turquoise.grey">
                szt.
              </Typography>
            )
          }}
          className={classes.quantity}
        />

        <Box className={classes.last}>
          {deletePackage ? (
            <ClearIcon
              sx={{ cursor: "pointer" }}
              onClick={() => deletePackage(transportOrderLineItem)}
            />
          ) : null}
        </Box>

        {addTransportOrderLineItem ? (
          <div className={classes.footer}>
            <Button
              variant="outlined"
              onClick={() =>
                addTransportOrderLineItem(transportOrderLineItem.category)
              }
            >
              Kolejna przesyłka
            </Button>
          </div>
        ) : (
          <Box className={classes.footer} sx={{ display: "none" }} />
        )}
      </Box>
    </Panel>
  );
};

const OthersFormPanel: React.FC<TOthersFormPanel> = (props) => {
  const {
    transportOrderLineItem,
    gtoliIndex,
    toliIndex,
    formik,
    addTransportOrderLineItem,
    deletePackage,
    categoryIcon = <BoxTapedIcon />
  } = props;

  const { classes } = useStyles();

  return (
    <Panel>
      <Box className={classes.othersPanel}>
        <Typography
          variant="body2"
          color="text.turquoise.dark"
          className={classes.nameLabel}
        >
          <strong>Nazwa</strong>
        </Typography>

        <Typography
          variant="body2"
          color="text.turquoise.dark"
          className={classes.dimensionsLabel}
        >
          <strong>Wymiary</strong>
        </Typography>

        <Typography
          variant="body2"
          color="text.turquoise.dark"
          className={classes.weightLabel}
        >
          <strong>Waga (1 szt.)</strong>
        </Typography>

        <Typography
          variant="body2"
          color="text.turquoise.dark"
          className={classes.quantityLabel}
        >
          <strong>Liczba</strong>
        </Typography>

        <div className={classes.category}>{categoryIcon}</div>

        <TextField
          name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.name`}
          value={transportOrderLineItem.name}
          onChange={formik.handleChange}
          error={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.name &&
            Boolean(
              getIn(
                getIn(
                  formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                  "transportOrderLineItems"
                )?.[toliIndex],
                "name"
              )
            )
          }
          helperText={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.name &&
            getIn(
              getIn(
                formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                "transportOrderLineItems"
              )?.[toliIndex],
              "name"
            )
          }
          className={classes.name}
        />

        <Stack direction="row" spacing={1} className={classes.dimensions}>
          <TextField
            name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.dimensionX`}
            value={transportOrderLineItem.dimensionX}
            onChange={formik.handleChange}
            error={
              formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
                ?.transportOrderLineItems?.[toliIndex]?.dimensionX &&
              Boolean(
                getIn(
                  getIn(
                    formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                    "transportOrderLineItems"
                  )?.[toliIndex],
                  "dimensionX"
                )
              )
            }
            helperText={
              formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
                ?.transportOrderLineItems?.[toliIndex]?.dimensionX &&
              getIn(
                getIn(
                  formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                  "transportOrderLineItems"
                )?.[toliIndex],
                "dimensionX"
              )
            }
            InputProps={{
              endAdornment: (
                <Typography variant="body2" color="text.turquoise.grey">
                  cm
                </Typography>
              )
            }}
          />

          <div>
            <MultiplyIcon />
          </div>

          <TextField
            name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.dimensionY`}
            value={transportOrderLineItem.dimensionY}
            onChange={formik.handleChange}
            error={
              formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
                ?.transportOrderLineItems?.[toliIndex]?.dimensionY &&
              Boolean(
                getIn(
                  getIn(
                    formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                    "transportOrderLineItems"
                  )?.[toliIndex],
                  "dimensionY"
                )
              )
            }
            helperText={
              formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
                ?.transportOrderLineItems?.[toliIndex]?.dimensionY &&
              getIn(
                getIn(
                  formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                  "transportOrderLineItems"
                )?.[toliIndex],
                "dimensionY"
              )
            }
            InputProps={{
              endAdornment: (
                <Typography variant="body2" color="text.turquoise.grey">
                  cm
                </Typography>
              )
            }}
          />

          <div>
            <MultiplyIcon />
          </div>

          <TextField
            name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.dimensionZ`}
            value={transportOrderLineItem.dimensionZ}
            onChange={formik.handleChange}
            error={
              formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
                ?.transportOrderLineItems?.[toliIndex]?.dimensionZ &&
              Boolean(
                getIn(
                  getIn(
                    formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                    "transportOrderLineItems"
                  )?.[toliIndex],
                  "dimensionZ"
                )
              )
            }
            helperText={
              formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
                ?.transportOrderLineItems?.[toliIndex]?.dimensionZ &&
              getIn(
                getIn(
                  formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                  "transportOrderLineItems"
                )?.[toliIndex],
                "dimensionZ"
              )
            }
            InputProps={{
              endAdornment: (
                <Typography variant="body2" color="text.turquoise.grey">
                  cm
                </Typography>
              )
            }}
          />
        </Stack>

        <TextField
          name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.weight`}
          value={transportOrderLineItem.weight}
          onChange={formik.handleChange}
          error={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.weight &&
            Boolean(
              getIn(
                getIn(
                  formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                  "transportOrderLineItems"
                )?.[toliIndex],
                "weight"
              )
            )
          }
          helperText={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.weight &&
            getIn(
              getIn(
                formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                "transportOrderLineItems"
              )?.[toliIndex],
              "weight"
            )
          }
          InputProps={{
            endAdornment: (
              <Typography variant="body2" color="text.turquoise.grey">
                kg
              </Typography>
            )
          }}
          className={classes.weight}
        />

        <TextField
          name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.quantity`}
          value={transportOrderLineItem.quantity}
          onChange={formik.handleChange}
          error={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.quantity &&
            Boolean(
              getIn(
                getIn(
                  formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                  "transportOrderLineItems"
                )?.[toliIndex],
                "quantity"
              )
            )
          }
          helperText={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.quantity &&
            getIn(
              getIn(
                formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                "transportOrderLineItems"
              )?.[toliIndex],
              "quantity"
            )
          }
          InputProps={{
            endAdornment: (
              <Typography variant="body2" color="text.turquoise.grey">
                szt.
              </Typography>
            )
          }}
          className={classes.quantity}
        />

        <Box className={classes.last}>
          {deletePackage ? (
            <ClearIcon
              sx={{ cursor: "pointer" }}
              onClick={() => deletePackage(transportOrderLineItem)}
            />
          ) : null}
        </Box>

        {addTransportOrderLineItem ? (
          <div className={classes.footer}>
            <Button
              variant="outlined"
              onClick={() =>
                addTransportOrderLineItem(transportOrderLineItem.category)
              }
            >
              Kolejna przesyłka
            </Button>
          </div>
        ) : (
          <Box className={classes.footer} sx={{ display: "none" }} />
        )}
      </Box>
    </Panel>
  );
};

const FurnitureFormPanel: React.FC<TFurnitureFormPanel> = (props) => {
  const {
    transportOrderLineItem,
    gtoliIndex,
    toliIndex,
    formik,
    addTransportOrderLineItem,
    deletePackage
  } = props;

  return (
    <OthersFormPanel
      transportOrderLineItem={transportOrderLineItem}
      gtoliIndex={gtoliIndex}
      toliIndex={toliIndex}
      formik={formik}
      addTransportOrderLineItem={addTransportOrderLineItem}
      deletePackage={deletePackage}
      categoryIcon={<LoveseatIcon />}
    />
  );
};

const VehiclesFormPanel: React.FC<TVehiclesFormPanel> = (props) => {
  const {
    transportOrderLineItem,
    gtoliIndex,
    toliIndex,
    formik,
    addTransportOrderLineItem,
    deletePackage
  } = props;

  return (
    <OthersFormPanel
      transportOrderLineItem={transportOrderLineItem}
      gtoliIndex={gtoliIndex}
      toliIndex={toliIndex}
      formik={formik}
      addTransportOrderLineItem={addTransportOrderLineItem}
      deletePackage={deletePackage}
      categoryIcon={<CarBusIcon />}
    />
  );
};

const FoodsFormPanel: React.FC<TFoodsFormPanel> = (props) => {
  const {
    transportOrderLineItem,
    gtoliIndex,
    toliIndex,
    formik,
    addTransportOrderLineItem,
    deletePackage
  } = props;

  const { classes } = useStyles();

  return (
    <Panel>
      <Box className={classes.foodsPanel}>
        <Typography
          variant="body2"
          color="text.turquoise.dark"
          className={classes.nameLabel}
        >
          <strong>Nazwa</strong>
        </Typography>

        <Typography
          variant="body2"
          color="text.turquoise.dark"
          className={classes.weightLabel}
        >
          <strong>Waga</strong>
        </Typography>

        <div className={classes.category}>
          <AppleGlassIcon />
        </div>

        <TextField
          name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.name`}
          value={transportOrderLineItem.name}
          onChange={formik.handleChange}
          error={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.name &&
            Boolean(
              getIn(
                getIn(
                  formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                  "transportOrderLineItems"
                )?.[toliIndex],
                "name"
              )
            )
          }
          helperText={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.name &&
            getIn(
              getIn(
                formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                "transportOrderLineItems"
              )?.[toliIndex],
              "name"
            )
          }
          className={classes.name}
        />

        <TextField
          name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.weight`}
          value={transportOrderLineItem.weight}
          onChange={formik.handleChange}
          error={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.weight &&
            Boolean(
              getIn(
                getIn(
                  formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                  "transportOrderLineItems"
                )?.[toliIndex],
                "weight"
              )
            )
          }
          helperText={
            formik.touched.groupedTransportOrderLineItems?.[gtoliIndex]
              ?.transportOrderLineItems?.[toliIndex]?.weight &&
            getIn(
              getIn(
                formik.errors.groupedTransportOrderLineItems?.[gtoliIndex],
                "transportOrderLineItems"
              )?.[toliIndex],
              "weight"
            )
          }
          InputProps={{
            endAdornment: (
              <Typography variant="body2" color="text.turquoise.grey">
                kg
              </Typography>
            )
          }}
          className={classes.weight}
        />

        <Box className={classes.last}>
          {deletePackage ? (
            <ClearIcon
              sx={{ cursor: "pointer" }}
              onClick={() => deletePackage(transportOrderLineItem)}
            />
          ) : null}
        </Box>

        {addTransportOrderLineItem ? (
          <div className={classes.footer}>
            <Button
              variant="outlined"
              onClick={() =>
                addTransportOrderLineItem(transportOrderLineItem.category)
              }
            >
              Kolejna przesyłka
            </Button>
          </div>
        ) : (
          <Box className={classes.footer} sx={{ display: "none" }} />
        )}
      </Box>
    </Panel>
  );
};

const TransportOrderLineItemFormPanel: React.FC<TTransportOrderLineItemFormPanel> =
  (props) => {
    const {
      transportOrderLineItem,
      gtoliIndex,
      toliIndex,
      formik,
      addTransportOrderLineItem,
      deletePackage
    } = props;

    switch (transportOrderLineItem.category) {
      case "people":
        return (
          <PeopleFormPanel
            transportOrderLineItem={transportOrderLineItem}
            gtoliIndex={gtoliIndex}
            toliIndex={toliIndex}
            formik={formik}
          />
        );
      case "others":
        return (
          <OthersFormPanel
            transportOrderLineItem={transportOrderLineItem}
            gtoliIndex={gtoliIndex}
            toliIndex={toliIndex}
            formik={formik}
            addTransportOrderLineItem={addTransportOrderLineItem}
            deletePackage={deletePackage}
          />
        );
      case "animals":
        return (
          <AnimalsFormPanel
            transportOrderLineItem={transportOrderLineItem}
            gtoliIndex={gtoliIndex}
            toliIndex={toliIndex}
            formik={formik}
            addTransportOrderLineItem={addTransportOrderLineItem}
            deletePackage={deletePackage}
          />
        );
      case "furniture":
        return (
          <FurnitureFormPanel
            transportOrderLineItem={transportOrderLineItem}
            gtoliIndex={gtoliIndex}
            toliIndex={toliIndex}
            formik={formik}
            addTransportOrderLineItem={addTransportOrderLineItem}
            deletePackage={deletePackage}
          />
        );
      case "vehicles":
        return (
          <VehiclesFormPanel
            transportOrderLineItem={transportOrderLineItem}
            gtoliIndex={gtoliIndex}
            toliIndex={toliIndex}
            formik={formik}
            addTransportOrderLineItem={addTransportOrderLineItem}
            deletePackage={deletePackage}
          />
        );
      case "foods":
        return (
          <FoodsFormPanel
            transportOrderLineItem={transportOrderLineItem}
            gtoliIndex={gtoliIndex}
            toliIndex={toliIndex}
            formik={formik}
            addTransportOrderLineItem={addTransportOrderLineItem}
            deletePackage={deletePackage}
          />
        );
      default:
        return null;
    }
  };

export default TransportOrderLineItemFormPanel;
