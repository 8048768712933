import React from "react";
import TravelerIcon from "@shared/ui/atoms/icons/TravelerIcon";
import CategoryCheckbox from "../CategoryCheckbox";

const PeopleCheckbox: React.FC<any> = (props) => {
  return (
    <CategoryCheckbox
      icon={<TravelerIcon className="icon" height="40px" />}
      label="Ludzie"
      {...props}
    />
  );
};

export default PeopleCheckbox;
