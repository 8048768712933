import React from "react";
import logo from "@assets/images/logo.svg";
import christmasLogo from "@assets/images/christmas_logo.svg";
import dayjs from "dayjs";

const Logo: React.FC<any> = () => {
  if ([0, 11].includes(dayjs().month())) {
    return <img src={christmasLogo} alt="christmas logo" />;
  }

  return <img src={logo} alt="logo" />;
};

export default Logo;
