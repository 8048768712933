import React from "react";
import Treasure from "@assets/images/category_icons/treasure_chest.svg";
import SVG from "react-inlinesvg";
import useColorFromPalette from "@shared/hooks/useColorFromPalette";

const TreasureIcon: React.FC<any> = (props) => {
  const { color = "turquoise.main", ...iconProps } = props;
  const colorValue = useColorFromPalette(color);

  return <SVG src={Treasure} height={32} color={colorValue} {...iconProps} />;
};

export default TreasureIcon;
