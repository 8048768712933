import React from "react";
import BoxTapedIcon from "@shared/ui/atoms/icons/BoxTapedIcon";
import CategoryCheckbox from "../CategoryCheckbox";

const OthersCheckbox: React.FC<any> = (props) => {
  return (
    <CategoryCheckbox
      icon={<BoxTapedIcon className="icon" height="40px" />}
      label="Pozostałe"
      {...props}
    />
  );
};

export default OthersCheckbox;
