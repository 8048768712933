import React from "react";
import {
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomAccordion from "@shared/ui/atoms/CustomAccordion";
import TransportOrderLineItem from "../TransportOrderLineItem";
import TransportOrderLineItemCategory from "../TransportOrderLineItemCategory";
import useStyles from "./GroupedTransportOrderLineItem.styles";

const GroupedTransportOrderLineItem: React.FC<any> = (props) => {
  const { groupedTransportOrderLineItem } = props;
  const { classes } = useStyles();

  if (groupedTransportOrderLineItem.transportOrderLineItems.length === 1) {
    return (
      <TransportOrderLineItem
        transportOrderLineItem={
          groupedTransportOrderLineItem.transportOrderLineItems[0]
        }
      />
    );
  }

  return (
    <CustomAccordion className={classes.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack spacing={2} direction="row" alignItems="center">
          <TransportOrderLineItemCategory
            category={groupedTransportOrderLineItem.category}
          />

          <Stack spacing={1} direction="column">
            <Typography variant="body2" color="text.turquoise.dark">
              <strong>Ilość</strong>
            </Typography>

            <Stack spacing={0.5} direction="row">
              <Typography variant="body2" color="text.grey.main">
                {groupedTransportOrderLineItem.quantity}
              </Typography>

              <Typography variant="body2" color="text.grey.main">
                szt.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <Stack spacing={2}>
          {groupedTransportOrderLineItem.transportOrderLineItems.map(
            (transportOrderLineItem: any) => (
              <React.Fragment key={transportOrderLineItem.id}>
                <Divider light sx={{ mt: 2 }} />

                <TransportOrderLineItem
                  transportOrderLineItem={transportOrderLineItem}
                  displayCategory={false}
                />
              </React.Fragment>
            )
          )}
        </Stack>
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default GroupedTransportOrderLineItem;
